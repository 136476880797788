#loader {
  display: flex;
  position: fixed;
  width: 100%;
  height: 100%;
  padding: 25px;
  background: gray;
  justify-content: center;
  align-items: center;
  background: black;
  /* display: none; */
  top: 0;
  left: 0;
  z-index: 9999;

  img {
    margin-bottom: 30px;
    max-width: 100%;
  }

  p {
    font-size: 1.5rem;
    animation: fadeinout 2s linear infinite;
  }
}

/* Add fade in and fade out animation for loading... text  */
@keyframes fadeinout {
  0% {
      opacity: 0;
  }

  50% {
      opacity: 1;
  }

  100% {
      opacity: 0;
  }
}
