@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'EndlessBossBattle';
  src: url('./fonts/endess_boss_battle.woff2') format('woff2'),
       url('./fonts/endless_boss_battle.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'pixelmixregular';
  src: url('./fonts/pixelmix-webfont.woff2') format('woff2'),
       url('./fonts/pixelmix-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}


// @font-face {
//   font-family: 'vt323regular';
//   src: url('./fonts/vt323-regular-webfont.woff2') format('woff2'),
//        url('./fonts/vt323-regular-webfont.woff') format('woff');
//   font-weight: normal;
//   font-style: normal;

// }

// @font-face {
//   font-family: 'vt323regular';
//   src: url('./fonts/vt323-regular-webfont.woff2') format('woff2'),
//        url('./fonts/vt323-regular-webfont.woff') format('woff');
//   font-weight: normal;
//   font-style: normal;

// }



body {
  background: black;
  font-size: 16px;
  color: white;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'pixelmixregular', Helvetica, sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input {
  color: black;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'EndlessBossBattle', Helvetica, sans-serif;
  margin-top: 0;
}

h2 {
  font-size: 30px;
  text-transform: uppercase;
  margin-bottom: 10px;
  color: #eeb60a;
  line-height: 1;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #E73D3D;
}

.app {
  max-width: 400px;
  margin: 0 auto;

  // Tab Navigation
  .react-tabs__tab {
    cursor: pointer;
    margin-right: 20px;
    opacity: 0.5;
    transition: opacity 0.2s ease-in-out;
    
    img {
      max-height: 70px;
    }

    &:last-child {
      margin-right: 0;
    }

    &[aria-selected="true"] {
      // border-bottom: 2px solid #eeb60a;
      opacity: 1;
    }

    &:hover {
      opacity: 1;
    }
  }

  // Forms
  input {
    padding: 7px 10px;
  }
}
